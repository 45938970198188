export const images1 = [
    "https://saintmarychurch.s3.us-east-1.amazonaws.com/images/1uuK_CTkF2t7zoa4ApJiYFN_kB1f-FuzT",
    "https://saintmarychurch.s3.us-east-1.amazonaws.com/images/1edY29V8tPqq0LbetBujJ7HZiS06vaeJq",
    "https://saintmarychurch.s3.us-east-1.amazonaws.com/images/1IHC06XtLEkFz57I4DzfXD_P7nRBFF3OA",
    "https://saintmarychurch.s3.us-east-1.amazonaws.com/images/1LtuDhQSwXLI6QQZOCEMRosXoyTQEAmt2",
    "https://saintmarychurch.s3.us-east-1.amazonaws.com/images/1frW7TdxVYaNUqe7D8UsDGNJ-aVwNFdwd"
]

export const images2 = [
    "https://saintmarychurch.s3.us-east-1.amazonaws.com/images/1iaQYjJaX-OdyZsruM2ti9bfFbTQnBPJx",
    "https://saintmarychurch.s3.us-east-1.amazonaws.com/images/1SimxkZIw-pvA7fdhQ-trW4LcBuovsaBk",
    "https://saintmarychurch.s3.us-east-1.amazonaws.com/images/15kZH16bjYkLLN4w7tz509tMXvFLpqr3x",
    "https://saintmarychurch.s3.us-east-1.amazonaws.com/images/1MfrWyNCNlHePcL-fz1FyATeVHgnLH58y",
    // "https://saintmarychurch.s3.us-east-1.amazonaws.com/images/1tW8Wvxq4X1VjfEys-Z6Vp_afZHiBubvu"
]
